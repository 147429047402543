import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { ProvenanceDetailPageService } from "../../../../../services";
import { toast } from "react-hot-toast";

const MODULE_NAME = "trackntrace.machines.states.verifyCredential";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    "services.ProvenanceDetailPageService"
  ) as ProvenanceDetailPageService;
};

export const verifyCredential = async (context: any, event: any) => {
  log.blue(MODULE_NAME, "context 1: ", context, "event 1: ", event);
  const method = "verifyCredential";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);

      const srv = getSrv();
      const content = await srv.verifyCredential(event);

      log.success("content in fullProvenance state", content);
      toast.success("Verified from Blockchain", { duration: 3000})
      if (content) {
        return resolve({
          key: "RENDER_PRODUCT_DETAIL_PAGE",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "Prouct Provenance not found";
        log.blue(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "RENDER_PRODUCT_DETAIL_PAGE_ERROR",
          value: msg,
        });
      }
    } catch (err: any) {
      log.blue(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "RENDER_PRODUCT_DETAIL_PAGE_ERROR",
        value: err?.message,
      });
    }
  });
};
